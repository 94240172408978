import React from 'react';

function Footer() {
  let date = new Date();
  let year = date.getFullYear();

  return (
    <div className="footer bg-light">
      <div className="container">
        <footer className="page-footer font-small blue mt-4">
          <div className="footer-copyright text-center py-3">Copyright © {year} All rights reserved.
        </div>
        </footer>
      </div>
    </div>

  );
}

export default Footer;
